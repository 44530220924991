import Swiper from 'swiper';
import 'bootstrap/js/dist/modal';

window.catalogApp = new Vue({
    el: "#catalog-wrap",
    data: {
        catSwiper: null,
        menuCardSwiper: null,
        sliderScroll: 0,
        customizable: {},
        selectedOption: {},
		infoProduct: {},
        activeCategory: gon.subcategories[0],
        subcategories: gon.subcategories,
        categories: gon.categories,
		productInfoVisible: false,
        products: gon.products,
        trackWidth: 682,
        toggleNames: {
            eggless: "Eggless",
            keto: "Keto",
            vegan: "Vegan",
            gluten_free: "Gluten Free",
            sugar_free: "Sugar Free",
        },
        filters: {
            search: "",
            toggles: {
                eggless: false,
                keto: false,
                vegan: false,
                gluten_free: false,
                sugar_free: false,
            },
        },
        preview: {},
        showPackaging: false,
    },
    methods: {
		currencyOptions(p){
			if(gon.currency == "$") {
				return p.usd_options;
			}
			 return p.options;
		},
		canAddTopper(product){
			return false;
		},
		showProductInfo(product){
			this.infoProduct = product;
			$('#product-storage-info').modal('show');
			try {
				mixpanel.track("Product Storage Info", {"Product": product.name});
			} catch(e){

			}
		},
		hasBuyable(cat){
			return cat.products.filter(x => !this.productDisabled(x)).length > 0;
		},
		productDisabled(p){
/*            if(p.category_names.indexOf("FLOWERS & MORE")>-1){
                return true;
            }*/
			return false;
			//return p.available_in.indexOf(this.orderCity) == -1;
		},

        togglePackaging(){
            this.showPackaging = !this.showPackaging;
        },
        hasTags(p){
            return this.isKeto(p) || this.isGlutenFree(p) || this.isSugarFree(p) || this.isVegan(p);
        },
        isKeto(p){
            return p.category_names.indexOf("KETO") > -1;
        },
        isVegan(p){
            return p.category_names.indexOf("VEGAN") > -1;
        },
        isSugarFree(p){
            return p.category_names.indexOf("SUGAR FREE") > -1;
        },
        isGlutenFree(p){
            return p.category_names.indexOf("GLUTEN FREE") > -1;
        },
		fromPrice(p){
			if(gon.currency == "$"){
				return Math.round(p.from_usd_price + (p.tax_rate * p.from_usd_price));
			} else {
			   	return Math.round(p.from_price + (p.from_price * p.tax_rate));
			}
        },
        newDisplayPrice(p){
            return gon.currency == "$" ? p.usd_price : p.price;
        },
        displayPrice(x){
            return thePrice(x);
        },
        selectSubCategory(cat){
            this.filters.search = "";
            this.activeCategory = cat;
            this.menuCardSwiper.slideTo(this.subcategories.indexOf(cat));
            try {
                mixpanel.track("Category Selection", {"Category": cat.name});
            } catch(e){

            }
            //this.scrollToCategory("#menu_card_" + cat.id);
            //this.sliderScroll = -1 * $("#category_selector_" + cat.id).position().left;
        },
        scrollToCategory(id) {
            var position = 0;
            $('html, body').stop().animate({
                scrollTop: position
            }, 300, 'swing');
        },
        productsToShow(category) {
			var result = category.products.filter(x => x.visible && !x.pos_only && !x.archived);
            Object.keys(this.filters.toggles).filter(x => this.filters.toggles[x]).forEach(toggle => {
                result = result.filter(x => x[toggle]);
            });

            var sortedResult = result.slice().sort(function(a,b){
                var catPositionA = a.category_product_positions.filter(x => x.category_id == category.id)[0];
                var catPositionB = b.category_product_positions.filter(x => x.category_id == category.id)[0];

                if(catPositionA){
                    catPositionA = catPositionA.position;
                }
                else {
                    catPositionA = 999;
                }
                if(catPositionB){
                    catPositionB = catPositionB.position;
                } else {
                    catPositionB = 999;
                }
                return catPositionA - catPositionB;
            });
            return sortedResult;
        },
        rupeeFormat(x){
            return x.toLocaleString("en-IN", {minimumFractionDigits: 0})
        },
        showPreview(product) {
            this.preview = product;
            $('#preview').modal('show');
            try {
                mixpanel.track("Product Preview", {"Product": product.name});
            } catch(e){

            }
        },
        showProductInList(product) {
            var retVal = true;
            Object.keys(this.filters.toggles).filter(x => this.filters.toggles[x]).forEach(x => {
                if (product[x] == false) {
                    retVal = false;
                }
            });
            return retVal;
        },
        slideRight(){
            this.sliderScroll-= 152;
            this.sliderScroll = Math.max(this.sliderScroll, -1 * this.trackWidth);
        },
        slideLeft(){
            this.sliderScroll+=152;
            if(this.sliderScroll>0){
                this.sliderScroll = 0;
            }
        },
    },
    computed: {
		relevantOptions() {
			if(gon.currency == "$"){
				return "usd_options";
			} else {
				return "options";
			}
		},

        filtersEnabled(){
            return Object.keys(this.filters.toggles).filter(x => this.filters.toggles[x]).length > 0;
        },
        matchingProducts() {
            let result = this.products;
            Object.keys(this.filters.toggles).filter(x => this.filters.toggles[x]).forEach(toggle => {
				result = result.filter(x => x[toggle]);
			});

            return result.filter(x => x.visible && !x.archived && !x.pos_only && x.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) > -1);
        },
    },
    mounted: function () {
        console.log("MOUNTED");
        var self = this;
        this.trackWidth = $('.subcategories-track').width() * 0.5;


        this.menuCardSwiper = new Swiper('.menu-card-swiper', {
            direction: 'horizontal',
            on: {
                slideChange: function(){
                    self.activeCategory = self.subcategories[self.menuCardSwiper.activeIndex];
                    var maxVisible = (self.catSwiper.activeIndex) + 1 * self.catSwiper.params.slidesPerView - 1;
                    var minVisible = self.catSwiper.activeIndex;
                    var selectedIndex = self.subcategories.indexOf(self.activeCategory);
                    if(selectedIndex > maxVisible){
                        self.catSwiper.slideTo(selectedIndex);
                    }
                    if(selectedIndex < minVisible){
                        self.catSwiper.slideTo(selectedIndex);
                    }
                    setTimeout(function(){
                        window.scrollTo(0,159);
                    },50);
                }
            }
        });
        this.catSwiper = new Swiper('.category-swiper', {
            direction: 'horizontal',
            slidesPerView: 4,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                480: {
                    slidesPerView: 2
                }
            },
            on: {
                slideChange: function(){
                    var maxVisible = (self.catSwiper.activeIndex) + 1 * self.catSwiper.params.slidesPerView - 1;
                    console.log("Max visible:", maxVisible);
                    console.log("Min Visible:", self.catSwiper.activeIndex);
                }
            }
        });
    },
});

